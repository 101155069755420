import React from "react";
import axios from "axios";

// Assets
import "../Assets/form.css";
import mainLogo from "../Assets/CDC-logo-01.jpg";
import {Quiz} from "./Quiz";

export class LandingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            isError: false,
            goToQuiz: false,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    // Form Input Handling
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // Send Data
    submitForm(e) {
        e.preventDefault();

        axios({
            method: 'post',
            url: `https://api.convertkit.com/v3/forms/2635361/subscribe`,
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            data: {
                api_key: 'Wb2bK3D__AiMpG6Z3eldBw',
                email: this.state.email,
                first_name: this.state.firstName
            }
        }).then((response) =>{
            // Go to Quiz
            this.setState({
                goToQuiz: true,
            })
        }).catch((error)=>{
            // Display Error
            console.log(error);

            this.setState({
                isError: true,
            })
        });
    }

    render() {
        return (
            <div className="App">
                { !this.state.goToQuiz
                    ? <div>
                        <img src={mainLogo} alt={"Clinician Development Collective Logo"} className="logo" />
                        <h3>Clinician Values Sort</h3>
                        <div className="desc">
                            <p className="p1">Do you know what your top values are in life?</p>
                            <p className="p1">Did you know that your values change every few years?</p>
                            <p>Take the values sort to find out your top five values. Use these values as a foundational understanding of what is most important to you.  Your values give you direction, purpose, and understanding of who you are and what sets you apart.</p>
                            <p>This is different from a quiz because you will actively sort through one hundred universal values and decipher which are your top five personal values.  After you have completed the Values Sort you will receive an email outlining your top five personal values and a downloadable exercise to help you build meaning around each personal values.</p>
                        </div>
                        <form onSubmit={this.submitForm}>
                            <input type="text" name="firstName" value={this.state.firstName} onChange={this.handleInputChange} placeholder="First Name" />
                            <input type="text" name="lastName" value={this.state.lastName} onChange={this.handleInputChange} placeholder="Last Name" />
                            <input type="email" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="Email"/>
                            <input type="submit" value="Take Quiz" />
                            {this.state.isError &&
                                <p>
                                    We ran into a problem, please verify inputs and try again
                                </p>
                            }
                        </form>
                        <br />
                        <br />
                    </div>
                    : <Quiz firstName={this.state.firstName} lastName={this.state.lastName} email={this.state.email} />
                }

            </div>
        );
    }
}


