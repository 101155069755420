import React from "react";

// Assets
import "../Assets/quiz.css";
import mainLogo from "../Assets/CDC-logo-01.jpg";
import axios from "axios";

export class Quiz extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            words: ["Health","Humor","Serenity","Parenting","Intellect","Dependability","Surrender","Pride","Respect","Service","Wealth","Equanimity","Sacredness","Excellence","Enlightenment","Imagination","Attention","Wholeness","Dignity","Reliability","Spirituality","Integrity","Relationships","Spontaneity","Control","Learning","Admiration","Home","Consistency","Honor","Self-Expression","Courage","Grace","Trust","Winning","Fairness","Friendship","Truth","Family","Safety","Rituals","Vitality","Community","Creativity","Calm","Kindness","Feelings","Pleasure","Honesty","Empathy","Security","Passion","Fun","Rules","Sexuality","Connection","Belief","Appreciation","Happiness","Faithfulness","Partnership","Support","Discovery","Persuasion","Patience","Play","Structure","Hope","Growth","Quiet","Openness","Gratitude","Leadership","Justice","Self-Respect","Order","Intuition","Inner Strength","Accomplishment","Independence","Challenge","Encouragement","Change","Adventure","Sensuality","Invention","Freedom","Tradition","Beauty","Mastery","Self-Control","Work","Nature","Power","Risk","Understanding","Contribution","Loyalty","Nurture","Strength","Planning","Joy","Peace","Action","Laughter","Love","Inspiration","Compassion","Recovery"  ],
            //words: ["Health","Humor","Serenity","Parenting","Intellect","Dependability","Surrender","Pride","Respect","Service","Wealth","Equanimity","Sacredness", "a", "b", "c", "d", "e", "f", "g", "h"],
            chosenWords: [],
            wordIndex: 0,
            stage: 0,
            candidateWords: [],
            totalFirstStageWords: 0,
            numToSelect: 20,
            readyForStage3: false,
            maxFor2: false,
            readyForStage4: false,
            maxFor3: false,
        }

        this.start = this.start.bind(this);
        this.addWord = this.addWord.bind(this);
        this.nextWord = this.nextWord.bind(this);
        this.selectWord = this.selectWord.bind(this);
        this.deselectWord = this.deselectWord.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.toStage3 = this.toStage3.bind(this);
        this.toStage4 = this.toStage4.bind(this);
    }

    start() {
        this.setState({
            stage: 1
        })
    }

    // If user presses yes, add word to chosenWords and go to next index
    addWord() {
        let updatedList = this.state.chosenWords.concat(this.state.words[this.state.wordIndex]);

        this.setState({
            chosenWords: updatedList,
            wordIndex: this.state.wordIndex + 1
        }, () => {
            this.checkStatus();
        });

    }

    // Move Index up 1 if user presses no
    nextWord() {
        this.setState({
            wordIndex: this.state.wordIndex + 1
        }, () => {
            this.checkStatus();
        });


        this.checkStatus();
    }

    //
    selectWord(word) {
        let updatedChosenWords = this.state.chosenWords.concat(word);
        let indexOfWord = this.state.candidateWords.indexOf(word);
        this.state.candidateWords.splice(indexOfWord, 1);

        this.setState({
            chosenWords: updatedChosenWords,
        }, () => {
            // Run Checks on Number of Words Added
            this.stageChecks();
        });
    }

    deselectWord(word) {
        let updatedCandidateWords = this.state.candidateWords.concat(word);
        let indexOfWord = this.state.chosenWords.indexOf(word);
        this.state.chosenWords.splice(indexOfWord, 1);

        this.setState({
            candidateWords: updatedCandidateWords,
        }, () => {
            // Run Checks on Number of Words Added
            this.stageChecks();
        });
    }

    stageChecks() {
        if (this.state.stage === 2) {
            if (this.state.chosenWords.length >= this.state.numToSelect) {
                this.setState({
                    readyForStage3: true,
                    maxFor2: true,
                })
            } else {
                this.setState({
                    readyForStage4: false,
                    maxFor2: false,
                })
            }
        }

        if (this.state.stage === 3) {
            // Limit to 10
            if (this.state.chosenWords.length >= 5) {
                this.setState({
                    maxFor3: true
                })
            } else {
                this.setState({
                    maxFor3: false
                })
            }

            // Make sure at least 5
            if (this.state.chosenWords.length >= 5) {
                this.setState({
                    readyForStage4: true
                })
            } else {
                this.setState({
                    readyForStage4: false
                })
            }
        }

    }

    // Run this after going to next word
    checkStatus() {
        // If at stage 1 see if list of words is over
        if (this.state.stage === 1) {
            if (this.state.wordIndex >= this.state.words.length) {
                let selectedMin = false;
                if (this.state.chosenWords.length >= 20) {
                    selectedMin = true;
                    console.log("min met");
                }

                this.setState({
                    selectedAtLeast20: selectedMin,
                    candidateWords: this.state.chosenWords,
                    totalFirstStageWords: this.state.chosenWords.length,
                    chosenWords: [],
                }, () => {
                    this.setState({
                        stage: 2,
                    })
                });
            }
        }
    }

    toStage3() {
        this.setState({
            stage: 3,
            candidateWords: this.state.chosenWords,
            chosenWords: []
        });
    }

    toStage4() {
        this.setState({
            stage: 4
        });

        this.sendEmail();
    }

    restart() {
        window.location.reload();
    }

    sendEmail() {
        // code fragment
        console.log(this.state.chosenWords)
        axios({
            method: 'post',
            url: `https://orca-app-lkzq6.ondigitalocean.app/finish`,
            data: {
                email: this.props.email,
                values: this.state.chosenWords,
                name: this.props.firstName + " " + this.props.lastName
            }
        }).then((response) =>{
            // Go to Quiz
            this.setState({
                goToQuiz: true,
            })
        }).catch((error)=>{
            // Display Error
            console.log(error);

            this.setState({
                isError: true,
            })
        });
    }

    render() {
        return (
            <div>
                { this.state.stage === 0 &&
                    <div className="desc">
                        <h3>First Step</h3>
                        <p>To begin, you will be presented with a universal value. Take a moment to reflect on this value.  If the universal value has a high importance to you, then click “yes” and if the value word is somewhat or not as important to you, then click “no.” </p>
                        <p>Tip: Try to choose values that are important to you, rather than values you think should be important, or instead important to someone else in your life.</p>
                        <button className="yesButton" onClick={this.start}>Start</button>
                    </div>
                }
                { this.state.stage === 1 &&
                    <div>
                        <div className="displayBox">
                            <h5>Values Left: {this.state.words.length-this.state.wordIndex}</h5>
                            <h5>Selected Values: {this.state.chosenWords.length}</h5>
                            <p>Note: Choose at least 20 values</p>
                            <p>Tip 2: If it takes you more than five seconds to click "yes" than your answer is likely, "no.</p>
                        </div>

                        <h6>Is this value important to you?</h6>
                        <h4>{this.state.words[this.state.wordIndex]}</h4>
                        <div className="buttonBox">
                        <button className="yesButton" onClick={this.addWord}>Yes</button>
                        <button className="noButton" onClick={this.nextWord}>No</button>
                        </div>
                    </div>
                }

                { (this.state.stage === 2 && this.state.selectedAtLeast20) &&
                    <div>
                        <div className="desc">
                            <h3>Step Two</h3>
                            <p>Now that you have gone through all the universal values and clicked “yes” to all the important personal values. Now you are going to reduce the values down to twenty. Select important personal values on the right-hand side.  This will then automatically place them in the list of “Twenty Personal Values” on the left side. The left column will count the number of important values you have selected.  Stop when you have selected a total of twenty.  When done then press enter.</p>
                        </div>
                        {this.state.readyForStage3
                            ? <button className="yesButton" onClick={this.toStage3}>Next</button>
                            : <button className="grayButton">Next</button>
                        }
                        <div className="half-grid">
                            <div>
                                <h3>Candidates [{this.state.candidateWords.length}]</h3>
                                <ul>
                                    {this.state.candidateWords.map((word) =>
                                        <div key={word.toString()} className="wordItem">{!this.state.maxFor2 ? <button className="yesButton" onClick={() => this.selectWord(word)}>+</button> : <button className="grayButton">✓</button>}<p>{word}</p></div>
                                    )}
                                </ul>
                            </div>
                            <div>
                                <h3>Selected [{this.state.chosenWords.length + "/" + (this.state.numToSelect)}]</h3>
                                <ul>
                                    {this.state.chosenWords.map((word) =>
                                        <div key={word.toString()} className="wordItem"><button className="noButton" onClick={() => this.deselectWord(word)}>-</button><p>{word}</p></div>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                }

                { (this.state.stage === 2 && !this.state.selectedAtLeast20) &&
                    <div>
                        <h3>You need to choose at least 20 words in order to continue, restart the quiz and select at least 20 words</h3>
                        <button className="yesButton" onClick={this.restart}>Restart</button>
                    </div>
                }

                { this.state.stage === 3 &&
                    <div>
                        <div className="desc">
                            <h3>Step Three</h3>
                            <p>Way to go! This is the last step. You will take the twenty personal values and reduce them down to your top five personal values. Once you are done press enter.</p>
                            <p>Tip: If you are struggling to reduce it to your top five personal values, try to group some of the values into one overarching value. </p>
                        </div>
                        {this.state.readyForStage4
                            ?<button className="yesButton" onClick={this.toStage4}>Next</button>
                            : <button className="grayButton">Next</button>
                        }
                        <div className="half-grid">
                            <div>
                                <h3>Candidates [{this.state.candidateWords.length}]</h3>
                                <ul>
                                    {this.state.candidateWords.map((word) =>
                                        <div key={word.toString()} className="wordItem">{!this.state.maxFor3 ? <button className="yesButton" onClick={() => this.selectWord(word)}>+</button> : <button className="grayButton">✓</button>}<p>{word}</p></div>
                                    )}
                                </ul>
                            </div>
                            <div>
                                <h3>Selected [{this.state.chosenWords.length + "/" + 5}]</h3>
                                <ul>
                                    {this.state.chosenWords.map((word) =>
                                        <div key={word.toString()} className="wordItem"><button className="noButton" onClick={() => this.deselectWord(word)}>-</button><p>{word}</p></div>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                }

                { this.state.stage === 4 &&
                    <div>
                        <img src={mainLogo} alt={"Clinician Development Collective Logo"} className="logo" />
                        <div className="paddedBox">
                            <h3>Step Four: You did it!</h3>
                            <p>You have identified your top five personal values!!  The values sort ensures that you have identified authentic and unique values that will shed a lot of light on what is important to you.  Use this as a tool to identify your next goals, build meaning, and highlight what makes you unique.  </p>
                            <p>Please check your email for your final list and then you can download the worksheet that will help you define and add meaning to the values you have just chosen.</p>
                            <p>Note: Please check your spam folder and let your email know that you want to keep messages from Clinician Development Collective in your inbox so future messages do not get lost in your spam folder.</p>
                        </div>

                        <h3>Your Top 5 Values</h3>
                        <div className="finalList">
                            {this.state.chosenWords.map((word) =>
                                <div key={word.toString()}><p>{word}</p></div>
                            )}
                        </div>
                    </div>
                }

            </div>
        );
    }
}


