import './App.css';
import {LandingPage} from "./components/LandingPage";

function App() {
    return (
        <LandingPage />
    );
}

export default App;
